/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import ReactDOM from "react-dom";

import { loadScript } from "./src/utils";
import "./static/fonts/fonts.css";

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.scrollTo(0, window.appCurrentScrollPosition);

  return [0, window.appCurrentScrollPosition];
  // return false;
};

export const onInitialClientRender = () => {
  loadScript(
    "https://cdn.cookielaw.org/consent/01915b93-ee42-7fa3-8adf-ff6c86835d1f/OtAutoBlock.js",
  );
  loadScript("https://cdn.cookielaw.org/scripttemplates/otSDKStub.js", {
    attributes: {
      charset: "UTF-8",
      "data-domain-script": "01915b93-ee42-7fa3-8adf-ff6c86835d1f",
    },
  });

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `function OptanonWrapper() { }`;
  document.body.appendChild(script);
};
