import { useEffect } from "react";

export const loadScript = (src, options = {}) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.type = "text/javascript";

    if (options.checkExisting === true) {
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        return resolve();
      }
    }

    if (options.attributes && options.attributes.constructor === Object) {
      const attributes = options.attributes;
      Object.keys(attributes).forEach((key) => {
        script.setAttribute(key, attributes[key]);
      });
    }

    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      // reject(new Error(`Failed to load script ${src}`));
    };

    document.body.appendChild(script);
  });
};

export const loadInlineScript = (content) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.innerHTML = content;
    document.body.appendChild(script);
    resolve();
  });
};

export const useOneTrust = (json) => {
  useEffect(() => {
    const loadScripts = async () => {
      await loadScript(
        "https://omnicom-privacy-cdn.my.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js",
        {
          //   checkExisting: true,
          attributes: {
            charset: "UTF-8",
            id: "otprivacy-notice-script",
            settings:
              "eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vb21uaWNvbS1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0=",
          },
        },
      );
      loadInlineScript(`
        function applyStylesToChildren(parentElement) {
          if (parentElement.hasChildNodes()) {
            parentElement.childNodes.forEach((child) => {
              if (child.nodeType === Node.ELEMENT_NODE) {
                child.style.backgroundColor = "transparent";
                child.style.color = "#fff";
                applyStylesToChildren(child);
              }
              if(child.nodeName === 'TD') {
                child.style.borderColor = '#fff'
              }
            });
          }
        }
        OneTrust.NoticeApi.Initialized.then(function() {
            OneTrust.NoticeApi.LoadNotices(["${json}"]).then( function() {
              [...document.querySelectorAll('.one-trust-page')].forEach( el => applyStylesToChildren(el))
            })
        });`);
    };
    loadScripts();
  }, []);
};
